import React, { Component } from "react"
import axios from "axios"
import qs from 'qs'
import Chart from '../components/chart'

let apiUrl = ''
let apiToken = ''
let siteUrl = ''
let cook_id = ''

class Sensor extends Component {
  constructor(props){
    super(props);
    
    cook_id = this.props.cook_id
    apiUrl = this.props.apiUrl
    apiToken = this.props.apiToken

    this.state = {
      sensorData : false,
      index : this.props.index,
      sensor_id : this.props.sensor_id
    }
    this.deleteSensor = this.deleteSensor.bind(this);
  }
  componentDidMount() {
    this.fetchCookData(this.state.sensor_id)
    this.interval = setInterval(() => this.fetchCookData(this.state.sensor_id), 60000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const sensor_id = this.state.sensor_id
    const index = this.state.index
    const sensorData = this.state.sensorData
    return (
      <div className="blockWrapper formBlock">
        <h2>Sensor {index +1}: {sensorData.name}</h2>
        { sensorData ? <Chart data={sensorData.data} sensor_id={sensor_id}/> : '' }
        <details>
          <summary>Edit Sensor</summary><br/>
          <div className="formWrapper">
            <form className="sensorForm" onSubmit={this.handleSubmit}>
              <input type="hidden" name="sensor_id" value={sensor_id}/>
              <ul>
                <li><label htmlFor={`name_`+sensor_id}>Sensor Name</label><input type="text" name="name" id={`name_`+sensor_id} defaultValue={sensorData.name} /></li>
                <li><label htmlFor={`high_limit_`+sensor_id}>High Limit</label><input className="num" type="text" name="high_limit" id={`high_limit_`+sensor_id} defaultValue={sensorData.high_limit} /></li>
                <li><label htmlFor={`low_limit_`+sensor_id}>Low Limit</label><input className="num" type="text" name="low_limit" id={`low_limit_`+sensor_id} defaultValue={sensorData.low_limit} /></li>
                <li className="center formButtons">
                  <button type="submit">Submit</button>
                  <button type="button" rel={sensor_id} onClick={this.deleteSensor}>Delete Sensor</button>
                </li>
              </ul>
            </form>
          </div>
        </details>
      </div>
    )
  }
  handleSubmit = event => {
    event.preventDefault();
    const rawFormData =  new FormData(event.target);
    let formData = {};
    formData.token = apiToken;
    for (var pair of rawFormData.entries()) {
      let key = pair[0];
      formData[key] = pair[1];
    }
    const myMethod = "PUT"
    const options = {
        method: myMethod,
        data: qs.stringify(formData),
        config:  { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }},
        url: apiUrl+`/sensor`
    }
    axios(options)
    .then((response) => {
      const myMessage = response.data.message
      this.setState({ message : myMessage },function (){
        window.location = siteUrl+'/cook?cook_id='+cook_id
      })
    })
    .catch(error => {
      this.setState({ loading: false, error })
    });
  }
  deleteSensor = (e) => {
    const id = e.target.getAttribute('rel');
    const myData = { 'token': apiToken, 'sensor_id': id };
    const options = {
      method: 'DELETE',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      params: myData,
      url: apiUrl+`/sensor`,
    };
    axios(options)
    .then(
      ()=>{
        window.location = siteUrl+'/cook?cook_id='+cook_id 
    })
    .catch(error => {
      this.setState({ loading: false, error })
    });
  }
  fetchCookData = (sensor_id) => {
    if (!cook_id) {
      this.setState({ sensorData: {} })
      return
    }
    axios
      .get(apiUrl+`/sensor?token=`+apiToken+`&cook_id=`+cook_id+`&sensor_id=`+sensor_id)
      .then(
        cookdata => {
        this.setState({
            sensorData: cookdata.data
        })
        if(this.state.cook.alarm_on && cookdata.data.alarmStatus){
          document.getElementById('alert_sound').play();
        }
      })
      .catch(error => {
        this.setState({ loading: false, error })
      })
  }
}
export default Sensor
