import React, { Component } from "react"
import axios from "axios"
import { graphql } from "gatsby"
import qs from 'qs'
import queryString from 'query-string'
import Cookies from 'js-cookie';
import alert_sound from "../sounds/ding.mp3"
import Sensors from "../components/sensors"
import "../styles/reset.css"
import "../styles/global.css"


export const siteMetaDataQuery = graphql`
query cookApiUrlQuery {
  site {
    siteMetadata {
      apiUrl,
      apiToken,
      siteUrl
    }
  }
}
`

let apiUrl = ''
let apiToken = ''
let siteUrl = ''

class CookDetail extends Component {
  componentDidMount() {
    this.fetchCookDetail()
  }
  constructor(props){
    super(props);

    apiUrl = this.props.data.site.siteMetadata.apiUrl
    apiToken = this.props.data.site.siteMetadata.apiToken
    siteUrl = this.props.data.site.siteMetadata.siteUrl

    this.state = {
      loading: false,
      error: false,
      cook: false,
      message: '',
      auth_token : Cookies.get('auth_token')
    }
    this.resetCook = this.resetCook.bind(this);
    this.deleteCook = this.deleteCook.bind(this);
  }
  render() {
    const auth_token = this.state.auth_token
    if(auth_token !== 'b5488b1e-02db-433f-8ca2-7a186da42ae9'){
      return (
        'Unauthorized'
      )
    }

    const cook = this.state.cook
    return (
      <div className="pageWrapper">
        <audio id="alert_sound">
          <source src={alert_sound} type="audio/mpeg" />
        </audio>
        {this.state.loading ? (
          <p>Loading...</p>
        ) : cook ? (
          <>
          {cook.id && cook.sensors ? 
            <div className="flexWrapper">
              <Sensors 
                cook={cook}
                apiUrl = {apiUrl}
                apiToken = {apiToken}
                siteUrl = {siteUrl}
              />
            </div>
            : '' }
            { !cook.status ? clearInterval(this.interval) : '' }
            <div className="blockWrapper formBlock">
              <nav>
                <ul>
                  <li><a href="/">Return to List</a></li>
                  {cook.id ? <li><button type="button" rel={cook.id} onClick={this.addSensor}>Add a Sensor</button></li> : ''}
                </ul>
              </nav>
              <details open={ cook.id === undefined ? 'open' : '' }>
                <summary>Edit cook: {cook.name}{ cook.alarm_on ? ' (Alarm On)' : '' }</summary><br/>
                <div className="formWrapper">
                  <div id="messages">{this.state.message}</div>
                  <form id="cookForm" onSubmit={this.handleSubmit}>
                    {cook.id ? <input type="hidden" name="cook_id" value={cook.id}/> : ''}
                    <ul>
                        {cook.id ? <li><label htmlFor="id">ID</label><input className="num" id="id" value={cook.id} disabled="disabled"/></li> : ''}
                        <li><label htmlFor="name">Name</label><input id="name" name="name" defaultValue={cook.name}/></li>
                        {cook.id ? <li><label htmlFor="status">Status</label><select id="status" name="status" defaultValue={cook.status}>
                            <option value="0">Closed</option>
                            <option value="1">Open</option>
                            </select></li> : ''}
                        {cook.id ? 
                            <li><label htmlFor="alarm_on">Alarm</label><select id="alarm_on" name="alarm_on" defaultValue={cook.alarm_on}>
                            <option value="0">Off</option>
                            <option value="1">On</option>
                            </select></li> : ''}
                            <li className="center"><button type="submit">Submit</button>
                            {cook.id ? <button type="button" rel={cook.id} onClick={this.resetCook}>Reset Cook</button> : ''}
                            {cook.id ? <button type="button" rel={cook.id} onClick={this.deleteCook}>Delete Cook</button> : ''}
                            </li>
                    </ul>
                  </form>
                </div>
              </details>
            </div>
          </>
        ) : (
          <p>Oh no, error fetching cook :(</p>
        )}
      </div>
    )
  }
  handleSubmit = event => {
    event.preventDefault();
    const rawFormData =  new FormData(event.target);
    let formData = {};
    formData.token = apiToken;
    for (var pair of rawFormData.entries()) {
      let key = pair[0];
      formData[key] = pair[1];
    }
    const myMethod = formData.cook_id ? "PUT" : "POST"
    const options = {
        method: myMethod,
        data: qs.stringify(formData),
        config:  { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }},
        url: apiUrl+`/cook`
    }
    axios(options)
    .then((response) => {
      const myMessage = response.data.message
      this.setState({ message : myMessage },function (){
        const id = response.data.id
        window.location = siteUrl+"/cook?cook_id="+id
      })
    })
    .catch(error => {
      this.setState({ loading: false, error })
    });
  }
  addSensor = (e) => {
    const id = e.target.getAttribute('rel');
    const myData = { 'token': apiToken, 'cook_id': id };
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      params: myData,
      url: apiUrl+`/sensor`,
    };
    axios(options)
    .then(
      ()=>{
        window.location = siteUrl+"/cook?cook_id="+id
    })
    .catch(error => {
      this.setState({ loading: false, error })
    });
  }
  deleteCook = (e) => {
    const id = e.target.getAttribute('rel');
    const myData = { 'token': apiToken, 'cook_id': id };
    const options = {
      method: 'DELETE',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      params: myData,
      url: apiUrl+`/cook`,
    };
    axios(options)
    .then(
      ()=>{
        window.location = siteUrl 
    })
    .catch(error => {
      this.setState({ loading: false, error })
    });
  }
  resetCook(e){
    const id = e.target.getAttribute('rel');
    const myData = { 'token': apiToken, 'cook_id': id };
    const options = {
      method: 'DELETE',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      params: myData,
      url: apiUrl+`/reset`,
    };
    axios(options)
    .then(
      ()=>{
      this.fetchCookDetail()}
    )
    .catch(error => {
      this.setState({ loading: false, error })
    });
  }
  fetchCookDetail = () => {
    const myQuery = this.props.location.search
    const cook_id = queryString.parse(myQuery).cook_id

    if (!cook_id) {
      this.setState({ cook: {} })
      return
    }
    this.setState({ loading: true })
    axios
      .get(apiUrl+`/cook?token=`+apiToken+`&cook_id=`+cook_id)
      .then(
        cook => {
        this.setState({
          loading: false,
          cook: cook.data
        })
      })
      .catch(error => {
        this.setState({ loading: false, error })
      })
  }
}
export default CookDetail
