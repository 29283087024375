import React, { Component } from "react"
import Sensor from "../components/sensor"

let apiUrl = ''
let apiToken = ''
let siteUrl = ''

class Sensors extends Component {
  constructor(props){
    super(props);
    apiUrl = this.props.apiUrl
    apiToken = this.props.apiToken
    siteUrl = this.props.siteUrl
  }

  render() {
    const cook = this.props.cook
    
    return (
        cook.sensors.map((item, index)=>
            <div key={item.id} className={`sensor_`+item.id}>
                <Sensor 
                    sensor_id = {item.id}
                    index = {index}
                    cook_id = {cook.id}
                    apiUrl = {apiUrl}
                    apiToken = {apiToken}
                    siteUrl = {siteUrl}
                />
            </div>
        )
    )
  }
}
export default Sensors
