import React, { PureComponent } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

export default class Chart extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/xqjtetw0/';

  render() {
    let min = 0;
    let max = 0;

    Object.values(this.props.data).map((item) => {
      min = item.value < min ? item.value : min;
      max = item.value > max ? item.value : max;
      max = item.high_limit > max ? item.high_limit : max;
      return '';
    })
    max = Math.ceil(max * 1.1);

    return (
      <LineChart
        width={500}
        height={300}
        data={this.props.data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="created_at" />
        <YAxis type="number" domain={[min, max]}/>
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="value" stroke="#82ca9d" dot={false} />
        <Line type="monotone" dataKey="high_limit" stroke="#FF0000" dot={false} />
        <Line type="monotone" dataKey="low_limit" stroke="#FF0000" dot={false} />
      </LineChart>
    );
  }
}
